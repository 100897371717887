.contentBody a {
    text-decoration: none;
    color: inherit;
}

.contentBody ol, ul, li {
    list-style: none;
    position: relative;
    padding-left: 13px;
}

.contentBody ul li::before {
    background: #6D282B;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 5px;
}

.no_bullets {
    background: #f9f9f9;
    border: 1px solid #aaa;
    padding: 10px;
    margin-bottom: 1em;
    width: auto;
    display: table;
    font-size: 95%;
}

.contentBody ul li:nth-child(n+2) {
    margin-top: 10px;
}

.toc_title {
    text-align: center;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.contentBody h2 {
    font-size: 1.375rem;
    margin: 60px 0 28px;
    padding-left: 22px;
}

.contentBody h2::before {
    background: #6D282B;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: 16px;
    width: 10px;
}

.contentBody h2 {
    font-family: "Noto Serif JP", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    margin: 40px 0 18px;
    padding-left: 18px;
    position: relative;
    color: #6D282B;
    font-weight: 700;
}

.contentBody h3 {
    border-bottom: 1px solid #C4C4C4;
    font-family: "Noto Serif JP", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    margin: 20px 0;
    padding-bottom: 14px;
    color: #6D282B;
    font-weight: 700;
}

.contentBody table {
    border-collapse: collapse;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    height: 208px;
}

.contentBody th {
    min-width: 1em;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(189, 189, 189);
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
    font-weight: 500;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.08);
}

.contentBody td {
    min-width: 1em;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(189, 189, 189);
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
}

.contentBody strong, b {
    color: #6D282B;
    font-weight: 700;
}

.contentBody blockquote {
    padding: 20px;
    border-left: 5px solid #ccc;
    background-color: #f9f9f9;
    color: #555;
}

.blog-card {
    border: 1px solid #999;
    word-wrap: break-word;
    max-width: 100%;
    border-radius: 0px;
    margin-bottom: 30px;
    font-size: 1.5em;
    transition: 0.1s ease;
    position: relative;
    padding-bottom: 10px;
}

.blog-card-thumbnail {
    float: left;
    padding: 10px;
    width: 180px;
}

.blog-card-title {
    padding: 13px 10px 10px 0;
    font-size: 90%;
}

.c-btn01__link {
    -webkit-transition: 0.25s linear;
    -webkit-transition-property: all;
    background: #6D282B;
    color: #fff!important;
    display: block;
    font-family: "Noto Serif JP", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
    font-size: 0.8125rem;
    font-weight: 600;
    font-weight: 600;
    padding: 16px 21px;
    text-align: center;
    transition: 0.25s linear;
    transition-property: all;
}

.hover-elevation {
    margin-bottom: 10px;
    padding: 10px;
    background: #F6F7F8;
    border: 1px solid hsl(215, 15%, 92%);
}
.hover-elevation:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14),
        0px 4px 3px rgba(0, 0, 0, 0.12); /* elevation={3} 相当 */
}

.contentBody a {
    color: #6D282B;
    text-decoration: none;
}
